.mco-collapsing + .mco-collapsing {
	margin-top: -56px;
}
@media all and (min-width: 960px) {
	.mco-collapsing + .mco-collapsing {
		margin-top: -80px;
	}
}

/*** Headlines + Text ***/

.mco-view-component-container-basic-headline--overline
	+ .mco-view-component-container-basic-headline--1 {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline--overline
	+ .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-text {
	margin-top: -24px;
}
.mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-text {
	margin-top: -24px;
}
.mco-view-component-container-basic-headline--2 + .mco-view-component-container-basic-text {
	margin-top: -24px;
}
.mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2 {
	margin-top: -48px;
}
.mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	h2 {
	color: #4f545c;
	font-size: clamp(20px, 5vw, 24px);
	font-weight: 400;
}
.mco-view-component-container-basic-headline--6 + .mco-view-component-container-basic-text {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-basic-headline {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
	margin-top: -32px;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-headline--overline
		+ .mco-view-component-container-basic-headline--1 {
		margin-top: -64px;
	}
	.mco-view-component-container-basic-headline--overline
		+ .mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-text {
		margin-top: -40px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-text {
		margin-top: -40px;
	}
	.mco-view-component-container-basic-headline--2 + .mco-view-component-container-basic-text {
		margin-top: -40px;
	}
	.mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2 {
		margin-top: -64px;
	}
	.mco-view-component-container-basic-headline--6 + .mco-view-component-container-basic-text {
		margin-top: -56px;
	}
	.mco-view-component-container-basic-headline + .mco-view-component-container-basic-headline {
		margin-top: -56px;
	}
	.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
		margin-top: -48px;
	}
}

/*** Teaser -> last-child ***/

.mco-elements > .mco-view-component-container-basic-teaser--slider:last-child {
	margin-bottom: 0 !important;
}

/*** Contact -> last-child ***/

.mco-elements > .mco-view-component-container-extension-contact:last-child {
	margin-bottom: 0 !important;
}

/*** Cubes - first-child ***/

.mco-elements > .mco-view-component-container-extension-cubes:first-child > div {
	transform: translate(0, 0) !important;
}

/*** Dropdown ***/

.mco-view-component-container-basic-dropdown-group
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline--2
	~ .mco-view-component-container-basic-dropdown-group
	.mco-dropdown-01__headline {
	font-size: 22px !important;
	padding-bottom: 8px !important;
}
.mco-view-component-container-basic-headline--2
	~ .mco-view-component-container-basic-dropdown-group
	.mco-dropdown-01__summary {
	min-height: auto !important;
}
.mco-view-component-container-basic-headline--2
	~ .mco-view-component-container-basic-dropdown-group
	.mco-dropdown-01__summary
	.Mui-expanded {
	min-height: auto !important;
}
.mco-view-component-container-basic-headline--2
	~ .mco-view-component-container-basic-dropdown-group
	.mco-dropdown-01__summary
	> .MuiButtonBase-root {
	margin-bottom: 8px !important;
	margin-left: 12px !important;
	padding: 0 !important;
}
.mco-view-component-container-basic-headline--2
	~ .mco-view-component-container-basic-dropdown-group
	.mco-dropdown-01__summary
	> .MuiButtonBase-root.Mui-expanded {
	margin-bottom: 8px !important;
}
.mco-view-component-container-basic-dropdown-group
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-headline {
	margin-top: 0;
}
.mco-view-component-container-basic-dropdown-group
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-text {
	margin-top: 0;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-dropdown-group
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -56px;
	}
	.mco-view-component-container-basic-headline--2
		~ .mco-view-component-container-basic-dropdown-group {
		margin-top: -40px !important;
	}
	.mco-view-component-container-basic-headline--2
		~ .mco-view-component-container-basic-dropdown-group
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -64px !important;
	}
	.mco-view-component-container-basic-headline--2
		~ .mco-view-component-container-basic-dropdown-group
		.mco-dropdown-01__headline {
		font-size: 24px !important;
	}
}

/*** Contact ***/

.mco-elements > .mco-view-component-container-extension-contact:first-child {
	margin-top: 0px !important;
}
