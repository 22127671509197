/*** Layout ***/

body.menu-active {
	position: relative;
}
body::before {
	background: #4b829b;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	pointer-events: none;
	top: 0;
	width: 100%;
	z-index: -100;
}
body.menu-active:before {
	opacity: 0.8;
	transition: opacity 500ms ease;
	z-index: 100;
}

/*** Headline ***/

.mco-view-component-container > .mco-headline {
	margin: 0 auto;
	max-width: 920px;
}
.mco-view-component-container-basic-headline--1 h1 {
	color: #164f69;
	padding-bottom: 24px;
	position: relative;
	text-align: center;
}
.mco-view-component-container-basic-headline--1 h1:after {
	background: #164f69;
	bottom: 0;
	content: "";
	height: 1px;
	left: 50%;
	position: absolute;
	transform: translate(-50%, 0);
	width: 80px;
}
.mco-view-component-container-basic-headline--2 h2 {
	color: #164f69;
	padding-bottom: 24px;
	position: relative;
	text-align: center;
}
.mco-view-component-container-basic-headline--2 h2::after {
	background: #164f69;
	bottom: 0;
	content: "";
	height: 1px;
	left: 50%;
	position: absolute;
	transform: translate(-50%, 0);
	width: 80px;
}
.mco-view-component-container-basic-headline h6 {
	color: #4f545c;
}
.mco-view-component-container-basic-headline--overline h6 {
	color: #848890;
	font-size: 16px;
	letter-spacing: 0.05em;
	text-align: center;
	text-transform: uppercase;
}
.mco-view-component-container-basic-headline--3 h3,
.mco-view-component-container-basic-headline--4 h4,
.mco-view-component-container-basic-headline--5 h5,
.mco-view-component-container-basic-headline--6 h6 {
	text-align: center;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-headline h2 {
		padding-bottom: 24px;
	}
}

/*** Text ***/

.mco-view-component-container-basic-text--justify .mco-text-01 {
	text-align: justify !important;
}
.mco-view-component-container-basic-text .mco-text-01 {
	margin: 0 auto !important;
	max-width: 920px !important;
}
.mco-view-component-container-basic-text--leftalign .mco-text-01 {
	margin: 0 auto 0 0 !important;
	max-width: 920px !important;
}

/*** Image-Text ***/

.mco-imagetext-01__title {
	color: #164f69 !important;
}
.mco-imagetext-01__subtitle {
	color: #848890 !important;
	font-size: 16px !important;
	font-weight: normal !important;
	letter-spacing: 0.05em !important;
	text-transform: uppercase !important;
}
.mco-imagetext-01__image img {
	height: auto !important;
}
@media all and (min-width: 960px) {
	.mco-imagetext-01 {
		grid-column-gap: 100px !important;
	}
	.mco-imagetext-01__subtitle,
	.mco-imagetext-01__title {
		margin-bottom: 16px !important;
	}
	.mco-imagetext-01__title {
		font-size: clamp(2rem, 8vw, 40px) !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-imagetext-01 {
		grid-column-gap: 140px !important;
	}
	.mco-imagetext-01__wrapper--left {
		grid-template-columns: 1fr 566px !important;
	}
	.mco-imagetext-01__wrapper--right {
		grid-template-columns: 566px 1fr !important;
	}
	.mco-imagetext-01__subtitle,
	.mco-imagetext-01__text {
		margin-bottom: 16px !important;
	}
}

/*** Gallery ***/

.mco-gallery-02 {
	margin: 0 auto !important;
	max-width: 920px !important;
}
.mco-gallery-02 .swiper-slide img {
	aspect-ratio: 3 / 2;
}
.mco-gallery-02-swiper-nav__next,
.mco-gallery-02-swiper-nav__prev {
	border-radius: 50% !important;
	width: 40px !important;
	height: 40px !important;
}
.mco-gallery-02-swiper-nav__prev {
	left: 16px !important;
	right: auto !important;
}
.mco-gallery-02-swiper-nav__next {
	left: auto !important;
	right: 16px !important;
}

/*** List ***/

.mco-list-01__headline {
	color: #4f545c !important;
}
.mco-list-01__list-item {
	padding: 8px 4px !important;
}

/*** Singleimage ***/

.mco-singleimage-01 {
	position: relative;
}
.mco-singleimage-01__content {
	background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) 49.51%, rgba(0, 0, 0, 0) 100%);
	bottom: 0;
	color: #ffffff !important;
	left: 0;
	padding: 16px !important;
	position: absolute;
	text-align: right;
	width: 100%;
}
.mco-singleimage-01__content-title {
	font-size: 24px !important;
	font-weight: normal !important;
}
.mco-singleimage-01__content-text {
	font-size: 16px !important;
	font-weight: normal !important;
}

/*** Links ***/

.mco-references-01__headline {
	color: #4b829b !important;
	margin-bottom: 32px !important;
	padding-bottom: 16px;
	position: relative;
}
.mco-references-01__headline::before {
	background: #4b829b;
	bottom: 0;
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	width: 80px;
}
.mco-references-01__list-item {
	margin-bottom: 8px !important;
}
.mco-references-01__list-item a {
	align-items: center;
	gap: 16px !important;
	transition: color 300ms ease;
}
.mco-references-01__list-item a:hover {
	color: #4b829b !important;
}
.mco-references-01__list-item a > div:not(.mco-typography) {
	align-items: center;
	background: #27638b;
	border-radius: 50%;
	color: #ffffff;
	display: flex;
	height: 40px;
	justify-content: center;
	transition: background 300ms ease;
	width: 40px;
}
.mco-references-01__list-item a:hover > div:not(.mco-typography) {
	background: #4b829b;
}
@media all and (min-width: 960px) {
	.mco-references-01__headline {
		padding-bottom: 24px;
	}
}

/*** Table ***/

.mco-table-01 {
	max-width: 920px !important;
}
.mco-table-01__headline {
	color: #4f545c !important;
}
.mco-table-01 .MuiTableCell-head {
	background: #fafafa !important;
	border-bottom: 2px solid rgba(153, 153, 153, 0.7);
	color: #4f545c !important;
	font-size: 16px !important;
	padding: 14px 8px !important;
}
.mco-table-01__cell {
	padding: 9px 8px !important;
}
.mco-table-01__row:nth-child(2n) td {
	background: #fafafa !important;
}
.mco-table-01__swipePopUp {
	background: rgba(0, 0, 0, 0.6) !important;
}
.mco-table-01__swipePopUp p {
	color: #ffffff !important;
}
.mco-table-01__swipePopUp svg path {
	fill: #ffffff !important;
}

/*** Video ***/

.mco-video-02__title {
	color: #4b829b !important;
}

/*** Download ***/

.mco-download-01 {
	background: #eaedef;
}
.mco-download-01__link {
	flex: 100%;
	height: 100%;
	padding: 8px;
}
.mco-download-01__link:hover {
	color: #4b829b !important;
}
.mco-download-01__link:hover svg path:first-child {
	fill: #4b829b !important;
}

/*** Datepicker ***/

.MuiPickersDatePickerRoot-toolbar button {
	height: auto !important;
	padding: 0 !important;
}

/*** Formular ***/

.mco-form-checkbox svg path {
	fill: #4b829b !important;
}
.mco-form-button-wrapper {
	display: flex;
	justify-content: flex-end;
}

/*** Singleteaser ***/

.mco-singleteaser-01 {
	background: #eaedef;
}
.mco-singleteaser-01__title {
	color: #164f69 !important;
	margin-bottom: 16px !important;
}
.mco-singleteaser-01__content {
	border: none !important;
	padding: 32px !important;
}
.mco-singleteaser-01__addition {
	margin-bottom: 16px !important;
}
.mco-singleteaser-01__text {
	margin-bottom: 32px !important;
}
.mco-singleteaser-01__button {
	justify-content: flex-start !important;
	margin-top: auto !important;
	padding: 0 !important;
}
@media all and (min-width: 960px) {
	.mco-singleteaser-01__image {
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
	.mco-singleteaser-01__content {
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-singleteaser-01__content {
		padding: 40px 64px 40px 40px !important;
	}
	.mco-singleteaser-01__title {
		font-size: clamp(2rem, 8vw, 40px) !important;
	}
}
.mco-view-component-container-basic-singleteaser--background {
	background: #7aa4b7;
	max-width: none !important;
	overflow: hidden;
	padding: 80px 40px !important;
	position: relative;
}
.mco-view-component-container-basic-singleteaser--background > div {
	padding: 0 !important;
}
.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01 {
	background: none;
	box-shadow: none !important;
}
.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__content {
	padding: 40px 0 0 !important;
}
.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__addition,
.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__title,
.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__text {
	color: #ffffff !important;
}
.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__text {
	margin-bottom: 0 !important;
}
.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__button {
	margin-top: 32px !important;
}
.mco-view-component-container-basic-singleteaser--background
	.mco-singleteaser-01__button
	button {
	background: #ffffff !important;
	color: #164f69 !important;
}
.mco-view-component-container-basic-singleteaser--background
	.mco-singleteaser-01__button
	button:hover {
	background: #eaedef !important;
}
@media all and (min-width: 600px) {
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01 > div {
		display: grid !important;
		grid-gap: 32px;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
	}
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__content {
		padding: 0 !important;
	}
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__image,
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__content {
		flex-grow: unset !important;
		max-width: none !important;
		flex-basis: unset !important;
	}
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__image {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__content {
		justify-content: center;
		grid-column: 2 / 3;
		grid-row: 1 / 2;
	}
}
@media all and (min-width: 960px) {
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01 {
		max-width: 1156px;
		margin-left: auto;
		margin-right: auto;
	}
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01 > div {
		grid-gap: 40px;
	}
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01__button {
		margin-top: 40px !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-singleteaser--background {
		padding: 120px 40px !important;
	}
	.mco-view-component-container-basic-singleteaser--background .mco-singleteaser-01 > div {
		grid-gap: 56px;
	}
}

/*** Dropdown ***/

.mco-dropdown-01 {
	border: none !important;
	padding: 0 !important;
}
.mco-dropdown-01__headline {
	color: #4b829b !important;
	font-size: clamp(1.5rem, 6vw, 36px) !important;
	padding-bottom: 16px !important;
}
.mco-dropdown-01__headline::before {
	background: #4b829b;
	bottom: 0;
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	width: 80px;
}
.mco-dropdown-01 .MuiButtonBase-root {
	margin-bottom: 16px !important;
}
.mco-dropdown-01__summary {
	background: none !important;
	justify-content: flex-start !important;
	padding: 0 !important;
}
.mco-dropdown-01 .MuiAccordionSummary-content {
	margin: 0 !important;
}
.mco-dropdown-01 .MuiAccordionSummary-content.Mui-expanded {
	margin: 0 !important;
	min-height: 48px !important;
}
.mco-dropdown-01 .MuiAccordionSummary-content {
	flex-grow: 0 !important;
}
.mco-dropdown-01__details > div > .mco-view-component-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
@media all and (min-width: 960px) {
	.mco-dropdown-01__headline {
		padding-bottom: 24px !important;
	}
	.mco-dropdown-01 .MuiButtonBase-root {
		margin-bottom: 24px !important;
	}
}

/*** Teaser Slider ***/

.mco-view-component-container-basic-teaser--slider {
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 48px !important;
	position: relative;
}
.mco-view-component-container-basic-teaser--slider::before {
	background: #eaedef;
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__textbox {
	margin: 25px 16px 32px !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__headline {
	color: #164f69 !important;
	font-size: clamp(2rem, 8vw, 40px);
	position: relative;
	margin-bottom: 24px;
	padding-bottom: 24px;
	position: relative;
	text-align: center;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__headline::before {
	background: #164f69;
	bottom: 0;
	content: "";
	height: 1px;
	left: 50%;
	margin: 0 0 0 -40px;
	position: absolute;
	width: 80px;
}
.mco-view-component-container-basic-teaser--slider .swiper-container {
	padding-left: 16px;
	padding-right: 16px;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next {
	right: 8px !important;
	transform: translate(0, calc(-50% - 24px));
}
.mco-view-component-container-basic-teaser--slider .swiper-button-prev {
	left: 8px !important;
	transform: translate(0, calc(-50% - 24px));
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next:after,
.mco-view-component-container-basic-teaser--slider .swiper-button-prev:after {
	display: none !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-disabled {
	opacity: 0 !important;
	pointer-events: none;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next div,
.mco-view-component-container-basic-teaser--slider .swiper-button-prev div {
	align-items: center;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	color: #ffffff;
	display: flex;
	height: 48px;
	justify-content: center;
	transition: background 300ms ease;
	width: 48px;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next:hover div,
.mco-view-component-container-basic-teaser--slider .swiper-button-prev:hover div {
	background: #27638b;
}
.mco-view-component-container-basic-teaser--slider .swiper-pagination {
	bottom: auto !important;
	position: relative !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-pagination-bullet {
	background: #27638b !important;
	border-radius: 8px !important;
	opacity: 0.5 !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-pagination-bullet-active {
	opacity: 1 !important;
	width: 16px !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__content {
	background: #ffffff !important;
	padding: 32px !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__title {
	margin-bottom: 16px !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__date span {
	color: #848890 !important;
	font-size: 16px !important;
	font-weight: bold !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__text {
	margin-top: 12px !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__button--bottom {
	margin-top: 24px !important;
}

@media all and (min-width: 1024px) {
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__textbox {
		margin: 80px 0 32px !important;
	}
	.mco-view-component-container-basic-teaser--slider {
		padding-left: 16px !important;
		padding-right: 16px !important;
		padding-bottom: 84px !important;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-container {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-pagination {
		justify-content: flex-start;
		margin-top: 32px !important;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__button--bottom {
		bottom: -24px !important;
		margin: 0 !important;
		position: absolute;
		right: 0 !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__headline {
		margin-bottom: 32px;
	}
}
@media all and (min-width: 1300px) {
	.mco-view-component-container-basic-teaser--slider .swiper-pagination {
		justify-content: center;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-button-next {
		right: -64px !important;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-button-prev {
		left: -64px !important;
	}
}
@media all and (min-width: 1400px) {
	.mco-view-component-container-basic-teaser--slider .swiper-button-next div,
	.mco-view-component-container-basic-teaser--slider .swiper-button-prev div {
		height: 64px;
		width: 64px;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-button-next {
		right: -104px !important;
		transform: translate(0, calc(-50% - 32px));
	}
	.mco-view-component-container-basic-teaser--slider .swiper-button-prev {
		left: -104px !important;
		transform: translate(0, calc(-50% - 32px));
	}
}

/*** Teaser Icon ***/

.mco-view-component-container-extension-icon-teaser {
	padding-left: 0 !important;
	padding-right: 0 !important;
	position: relative;
}
.mco-view-component-container-extension-icon-teaser .swiper-container {
	padding: 32px 16px;
}
.mco-view-component-container-extension-icon-teaser .swiper-slide {
	background: #ffffff;
	box-shadow: 0px 10px 15px 0px #0000001a;
}
.mco-view-component-container-extension-icon-teaser .swiper-button-next {
	right: 8px !important;
	transform: translate(0, calc(-50% - 24px));
}
.mco-view-component-container-extension-icon-teaser .swiper-button-prev {
	left: 8px !important;
	transform: translate(0, calc(-50% - 24px));
}
.mco-view-component-container-extension-icon-teaser .swiper-button-disabled {
	opacity: 0 !important;
	pointer-events: none;
}
.mco-view-component-container-extension-icon-teaser .swiper-button-next div,
.mco-view-component-container-extension-icon-teaser .swiper-button-prev div {
	align-items: center;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	color: #ffffff;
	display: flex;
	height: 48px;
	justify-content: center;
	transition: background 300ms ease;
	width: 48px;
}
.mco-view-component-container-extension-icon-teaser .swiper-button-next:hover div,
.mco-view-component-container-extension-icon-teaser .swiper-button-prev:hover div {
	background: #27638b;
}
@media all and (min-width: 1024px) {
	.mco-view-component-container-extension-icon-teaser {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.mco-view-component-container-extension-icon-teaser .swiper-container {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}
}
@media all and (min-width: 1300px) {
	.mco-view-component-container-extension-icon-teaser .swiper-button-next {
		right: -64px !important;
	}
	.mco-view-component-container-extension-icon-teaser .swiper-button-prev {
		left: -64px !important;
	}
}
@media all and (min-width: 1400px) {
	.mco-view-component-container-extension-icon-teaser .swiper-button-next div,
	.mco-view-component-container-extension-icon-teaser .swiper-button-prev div {
		height: 64px;
		width: 64px;
	}
	.mco-view-component-container-extension-icon-teaser .swiper-button-next {
		right: -104px !important;
		transform: translate(0, calc(-50% - 32px));
	}
	.mco-view-component-container-extension-icon-teaser .swiper-button-prev {
		left: -104px !important;
		transform: translate(0, calc(-50% - 32px));
	}
}

/*** Teaser Grid ***/

.mco-view-component-container-basic-teaser--grid {
	position: relative;
	padding: 40px 0 !important;
}
.mco-view-component-container-basic-teaser--grid::before {
	background: #7aa4b7;
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__headline {
	color: #ffffff !important;
	font-size: clamp(2rem, 8vw, 40px);
	position: relative;
	margin-bottom: 24px;
	padding-bottom: 24px;
	position: relative;
	text-align: center;
}
.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__headline::before {
	background: #ffffff;
	bottom: 0;
	content: "";
	height: 1px;
	left: 50%;
	margin: 0 0 0 -40px;
	position: absolute;
	width: 80px;
}
.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__button--bottom {
	margin-top: 40px !important;
}
.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__button--bottom button {
	background: #ffffff !important;
	color: #164f69 !important;
}
.mco-view-component-container-basic-teaser--grid
	.mco-teaser-list-01__button--bottom
	button
	svg {
	width: 24px !important;
}
.mco-teaser-02__image {
	overflow: hidden;
	position: relative;
}
.mco-teaser-02__image::before {
	background: #27638b;
	content: "";
	opacity: 0.3;
	inset: 0;
	position: absolute;
	transition: opacity 500ms ease;
	z-index: 1;
}
.mco-teaser-02 a:hover .mco-teaser-02__image::before {
	opacity: 0.8;
}
.mco-teaser-02 .mco-teaser-02__image img {
	transition: transform 500ms ease !important;
	backface-visibility: hidden;
}
.mco-teaser-02 a:hover .mco-teaser-02__image img {
	filter: none !important;
	transform: scale(1.05);
}
.mco-teaser-02__content {
	align-items: center;
	display: flex !important;
	justify-content: center;
	padding: 24px !important;
}
.mco-teaser-02 a:hover .mco-teaser-02__content {
	backdrop-filter: unset !important;
}
.mco-teaser-02__content > .mco-teaser-02__title {
	color: #ffffff;
	margin: 0;
	text-align: center;
	text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
.mco-teaser-02__button {
	display: none !important;
}

@media all and (min-width: 600px) {
	.mco-view-component-container-basic-teaser--grid {
		overflow: hidden;
		position: relative;
		padding: 0 0 48px !important;
	}
	.mco-view-component-container-basic-teaser--grid::before {
		display: none;
	}
	.mco-view-component-container-basic-teaser--grid .MuiGrid-container {
		position: relative;
	}
	.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__button--bottom {
		margin: 0 auto !important;
		padding: 40px 0 !important;
		position: relative;
	}
	.mco-view-component-container-basic-teaser--grid .MuiGrid-container::before {
		background: #7aa4b7;
		bottom: -48px;
		content: "";
		left: -4000px;
		position: absolute;
		top: 160px;
		width: 8000px;
	}
	.mco-view-component-container-basic-teaser--grid
		.mco-teaser-list-01__button--bottom::before {
		background: #7aa4b7;
		bottom: 0;
		content: "";
		left: -4000px;
		position: absolute;
		top: 0;
		width: 8000px;
	}
	.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__headline {
		color: #164f69 !important;
	}
	.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__headline::before {
		background: #164f69;
	}
	.mco-view-component-container-basic-teaser--grid .MuiGrid-container {
		width: calc(100% + 24px);
		margin: -12px;
	}
	.mco-view-component-container-basic-teaser--grid .MuiGrid-container .MuiGrid-item {
		padding: 12px;
	}
}

@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-teaser--grid .mco-teaser-list-01__headline {
		margin-bottom: 32px;
	}
}

/*** Contact ***/

.mco-view-component-container-extension-contact {
	background: #eaedef;
	padding-bottom: 48px !important;
	padding-top: 48px !important;
	position: relative;
}
@media all and (min-width: 1280px) {
	.mco-view-component-container-extension-contact {
		padding-bottom: 80px !important;
		padding-top: 80px !important;
	}
}

/*** Infobox ***/

.mco-view-component-container-extension-infobox {
	background: #eaedef;
	padding-bottom: 48px !important;
	padding-top: 48px !important;
	position: relative;
}
@media all and (min-width: 960px) {
	.mco-view-component-container-extension-infobox {
		margin-left: 0 !important;
		margin-right: 0 !important;
		padding-bottom: 80px !important;
		padding-top: 80px !important;
	}
}

@media all and (min-width: 1280px) {
	.mco-view-component-container-extension-infobox {
		padding-bottom: 120px !important;
		padding-top: 120px !important;
	}
}

@media all and (min-width: 1440px) {
	.mco-view-component-container-extension-infobox {
		margin-left: auto !important;
		margin-right: auto !important;
	}
}

/*** Overlay ***/

.mco-overlay-backdrop {
	backdrop-filter: blur(5px);
}
.mco-overlay.overlay-e-card .mco-overlay-backdrop {
	backdrop-filter: unset !important;
}

/*** E-Book Teaser ***/

.mco-ebook-teaser-01 {
	background: #eaedef !important;
}
.mco-ebook-teaser-01__browse button {
	color: #ffffff !important;
}

/*** Search Results ***/

.mco-search-results__item {
	background: #eaedef !important;
}
.mco-search-results__item button {
	background: none !important;
	box-shadow: none !important;
}
.mco-search-results__item button svg path {
	fill: #27638b !important;
}

/*** Interaktionslayer ***/

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	color: #fff !important;
	background-color: #27638b !important;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
		0px 1px 5px 0px rgb(0 0 0 / 12%);
	display: flex;
	align-items: center;
	height: 48px !important;
	padding: 12px 24px !important;
	font-size: 16px;
	min-width: 64px;
	box-sizing: border-box;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-family: Lato;
	font-weight: 700;
	line-height: 1;
	border-radius: 64px;
	letter-spacing: 0.05em;
	text-transform: unset !important;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: #4b829b !important;
}
.micado-ial-item-notification__button {
	border-radius: 4px;
}
.micado-ial__notification .micado-ial__close:hover .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close:hover .micado-svg-icon svg path {
	fill: #ffffff;
}
.micado-ial-item-notification__button--is-icon:hover {
	background: #4b829b !important;
}
.micado-overlay__close {
	background: #eaedef !important;
}
.micado-overlay__close:hover {
	background: #27638b !important;
}
.micado-overlay__close:hover .micado-svg-icon svg path {
	fill: #ffffff !important;
}
.micado-ial-item-message__buttons {
	justify-content: flex-end !important;
}
